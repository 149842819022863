import usFlag from "../assets/images/flags/us.jpg"
import turkey from "../assets/images/flags/turkey.png"

const languages = {
  tr: {
    label: "Turkish",
    flag: turkey,
  },
  en: {
    label: "English",
    flag: usFlag,
  }
}

export default languages

import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";


import Dropzone from 'react-dropzone-uploader';

import {
  getCompany as onGetCompany,
  addCompany as onAddCompany,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "REHBER | BERLİN ESNAF ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState('');


  const [getHtmlDataLong, setHtmlDataLong] = useState();

  const [getFileSubMedia, setFileSubMedia] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const [getIsActive, setIsActive] = useState(false);
  const [getIsService, setIsService] = useState(false);
  const [getIsPremium, setIsPremium] = useState(false);

  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Company_Name) || "",


      address: (contactEdit && contactEdit.Company_Adress) || "",
      desc: (contactEdit && contactEdit.Company_Description) || "",


      lat: (contactEdit && contactEdit.Company_Location_Lat) || "",
      long: (contactEdit && contactEdit.Company_Location_Lng) || "",

      web: (contactEdit && contactEdit.Company_WebSite) || "",
      tel: (contactEdit && contactEdit.Company_TelephoneNumber) || "",
      sort: (contactEdit && contactEdit.Sort) || "",
      mail: (contactEdit && contactEdit.Company_MailAdress) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: contactEdit.ID,
          Company_Name: values.title,
          Company_Image: getFileName,
          Company_Adress:values.address,
          Company_Location_Lat: parseFloat(values.lat),
          Company_Location_Lng: parseFloat(values.long),
          Users_ID: getCategoryIDSecond,
          Company_WebSite: values.web,
          Company_Description:values.desc,
          Company_Advertised: getIsPremium,
          Company_Active: getIsActive,
          Company_SubCategory_ID: getCategoryID,
          Company_TelephoneNumber:values.tel,
          Company_MailAdress:values.mail,
          Company_IsAdvert: getIsService,
         // Company_TelephoneNumber2
          Sort : parseInt(values.sort)
        };
        dispatch(onUpdateCompany(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          
          Company_Name: values["title"],
          Company_Image: getFileName,
          Company_Adress:values["address"],
          Company_Location_Lat: parseFloat(values["lat"]),
          Company_Location_Lng: parseFloat(values["long"]),
          Users_ID: getCategoryIDSecond,
          Company_WebSite: values["web"],
          Company_Description:values["desc"],
          Company_Advertised: getIsPremium,
          Company_Active: getIsActive,
          Company_SubCategory_ID: getCategoryID,
          Company_TelephoneNumber:values["tel"],
          Company_MailAdress:values["tel"],
          Company_IsAdvert: getIsService,
         // Company_TelephoneNumber2
          Sort : parseInt(values["mail"])

        };
        // save new user
        dispatch(onAddCompany(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.company,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategoryIDSecond, setCategoryIDSecond] = useState(null);


  const [getCategory, setCategory] = useState([]);
  const [getCategorySecond, setCategorySecond] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Company_Image ? (
              <div className="avatar-xs">
                {cellProps.Company_Name != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Company_Name.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.Company_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Başlık",
        accessor: "Company_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Adres",
        accessor: "Company_Adress",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Reklam İlanı Mı?",
        accessor: "Company_IsAdvert",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "Premium Mu?",
        accessor: "Company_Advertised",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "Aktif Mi?",
        accessor: "Company_Active",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "Sıralama?",
        accessor: "Sort",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCompany_SubCategoryBerlin/select/`)
      .then((res) => {
        setCategory(res.data)
      })


  }

  const _getAuthDataSecond = async () => {


    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersBerlin/select/`)
      .then((res) => {
        setCategorySecond(res.data)
      })
  }







  useEffect(() => {
    _getAuthData()
    _getAuthDataSecond()
  


    if (users && !users.length) {
      dispatch(onGetCompany());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };




  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

  


    setCategoryID(user.Company_SubCategory_ID)
    setCategoryIDSecond(user.Users_ID)


    setIsActive(user.Company_Active)
  


    setIsPremium(user.Company_Advertised)

    setIsService(user.Company_IsAdvert)
   
    setFileName(user.Company_Image)
    setIsEdit(true);


    toggle();
  };




  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteCompany(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const _setChoiceCategorySecond = arg => {
    setCategoryIDSecond(arg.target.value)

  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _addMedia = () => {


    getFileSubMediaAdd.map((item, index) => {
      const newUser = {
        Education_SubItem_ID: getSubMediaID,
        EducationSubItem_Media_Type: item.fileType,
        EducationSubItem_Media_Link: item.fileName
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addSubEducationMediaDiz/send', newUser)

      dispatch(onGetCompany());

      toggleMedia();
    })
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          console.log("lnslkdf", result.file)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Rehber Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddCompany={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Medya Ekle"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>


                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatusBanner}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*,video/*"
                            />
                          </div>

                        </Col>
                      </Row>
                      <Row>

                        {getFileSubMedia.map((item, index) => (
                          item.EducationSubItem_Media_Type == "video/mp4" ?
                            <div key={index}>
                              <video width="150" height="150" controls>
                                <source src={'https://megasubdomain.stechomeyazilim.info/' + item.EducationSubItem_Media_Link} type="video/mp4" />
                              </video>

                            </div> :

                            <div key={index}>
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + item.EducationSubItem_Media_Link} alt="" className="avatar-sm" />
                            </div>
                        ))}



                        <Col>
                          <div className="text-end">
                            <button
                              onClick={() => _addMedia()}
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Medyaları Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>

                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kampanya Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                            <div className="mb-3">
                              <Label className="form-label">Rehber Alt Kategori Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                  <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Company_SubCategory_Text}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>





                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryIDSecond}
                                onChange={(text) => _setChoiceCategorySecond(text)}
                                value={
                                  getCategoryIDSecond
                                }>
                                {getCategorySecond.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Name_Surname}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>


                    
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Rehber Başlığını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Açıklama</Label>
                              <Input
                                name="desc"
                                label="desc"
                                type="text"
                                placeholder="Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Web Site</Label>
                              <Input
                                name="web"
                                label="web"
                                type="text"
                                placeholder="Web Site Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.web || ""}
                                invalid={
                                  validation.touched.web &&
                                    validation.errors.web
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.web &&
                                validation.errors.web ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.web}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Telefon</Label>
                              <Input
                                name="tel"
                                label="tel"
                                type="text"
                                placeholder="Telefon Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tel || ""}
                                invalid={
                                  validation.touched.tel &&
                                    validation.errors.tel
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tel &&
                                validation.errors.tel ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tel}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Mail Adresi</Label>
                              <Input
                                name="mail"
                                label="mail"
                                type="text"
                                placeholder="Mail Adresini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mail || ""}
                                invalid={
                                  validation.touched.mail &&
                                    validation.errors.mail
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mail &&
                                validation.errors.mail ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mail}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Boylam</Label>
                              <Input
                                name="long"
                                label="long"
                                type="text"
                                placeholder="Boylam Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.long || ""}
                                invalid={
                                  validation.touched.long &&
                                    validation.errors.long
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.long &&
                                validation.errors.long ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.long}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Enlem</Label>
                              <Input
                                name="lat"
                                label="lat"
                                type="text"
                                placeholder="Enlem Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lat || ""}
                                invalid={
                                  validation.touched.lat &&
                                    validation.errors.lat
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lat &&
                                validation.errors.lat ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lat}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Adres</Label>
                              <Input
                                name="address"
                                label="address"
                                type="text"
                                placeholder="Adres Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                    validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                                validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                       

                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="number"
                                placeholder="Sıralama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>

                    


                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>


                            <div className="d-flex">
                              <label htmlFor="resume">Rehber reklam alanında görünsün mü?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch2"
                                  className="switch"
                                  defaultChecked={getIsService}
                                  onChange={() =>
                                    setIsService(!getIsService)
                                  }
                                />
                                <label
                                  htmlFor="square-switch2"
                                  data-on-label="Evet "
                                  data-off-label="Hayır"
                                />
                              </div>

                            </div>


                            <div className="d-flex">
                              <label htmlFor="resume">Ücretli mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch2"
                                  className="switch"
                                  defaultChecked={getIsPremium}
                                  onChange={() =>
                                    setIsPremium(!getIsPremium)
                                  }
                                />
                                <label
                                  htmlFor="square-switch2"
                                  data-on-label="Evet "
                                  data-off-label="Hayır"
                                />
                              </div>

                            </div>
                       

                            

                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>



                            <div >
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + getFileName} alt="" className="avatar-sm" />
                            </div>
                       
                         






                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);

import axios from "axios";
import { del, get, post, put, patch } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events

// add Events

// update Event


// delete Event
export const deleteEvent = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteEventMega/${project}`);


// get Categories

// get chats

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages


// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);



export const getArticleDay= () => get(`https://getjob.stechomeyazilim.info:5101/getArticleDayBerlin/select/`);
export const addArticleDay = job => post(`https://getjob.stechomeyazilim.info:5101/addArticleDayBerlin/send`, job);
export const updateArticleDay = user => patch(`https://getjob.stechomeyazilim.info:5101/updateArticleDayBerlin/${user.ID}`, user);
export const deleteArticleDay= user => del(`https://getjob.stechomeyazilim.info:5101/deleteArticleDayBerlin/${user}`);


export const getNews_Category= () => get(`https://getjob.stechomeyazilim.info:5101/getNews_CategoryBerlin/select/`);
export const addNews_Category = job => post(`https://getjob.stechomeyazilim.info:5101/addNews_CategoryBerlin/send`, job);
export const updateNews_Category = user => patch(`https://getjob.stechomeyazilim.info:5101/updateNews_CategoryBerlin/${user.ID}`, user);
export const deleteNews_Category= user => del(`https://getjob.stechomeyazilim.info:5101/deleteNews_CategoryBerlin/${user}`);

export const getSongCategory= () => get(`https://getjob.stechomeyazilim.info:5101/getSong_CategoryBerlin/select/`);
export const addSongCategory = job => post(`https://getjob.stechomeyazilim.info:5101/addSong_CategoryBerlin/send`, job);
export const updateSongCategory = user => patch(`https://getjob.stechomeyazilim.info:5101/updateSong_CategoryBerlin/${user.ID}`, user);
export const deleteSongCategory= user => del(`https://getjob.stechomeyazilim.info:5101/deleteSong_CategoryBerlin/${user}`);



export const getNews= () => get(`https://getjob.stechomeyazilim.info:5101/getNewsBerlin/select/`);
export const addNews = job => post(`https://getjob.stechomeyazilim.info:5101/addNewsBerlin/send`, job);
export const updateNews = user => patch(`https://getjob.stechomeyazilim.info:5101/updateNewsBerlin/${user.ID}`, user);
export const deleteNews = user => del(`https://getjob.stechomeyazilim.info:5101/deleteNewsBerlin/${user}`);

export const getNe= () => get(`https://getjob.stechomeyazilim.info:5101/getNewsBerlin/select/`);
export const addNe = job => post(`https://getjob.stechomeyazilim.info:5101/addNewsBerlin/send`, job);
export const updateNe = user => patch(`https://getjob.stechomeyazilim.info:5101/updateNewsBerlin/${user.ID}`, user);
export const deleteNe = user => del(`https://getjob.stechomeyazilim.info:5101/deleteNewsBerlin/${user}`);



export const getNews_Comment= () => get(`https://getjob.stechomeyazilim.info:5101/getNews_CommentBerlin/select/`);
export const deleteNews_Comment = user => del(`https://getjob.stechomeyazilim.info:5101/deleteNews_CommentBerlin/${user}`);
export const addForum = job => post(`https://getjob.stechomeyazilim.info:5101/addForumBerlin/send`, job);




export const getPool= () => get(`https://getjob.stechomeyazilim.info:5101/getPoolBerlin/select/`);
export const addPool = job => post(`https://getjob.stechomeyazilim.info:5101/addPoolBerlin/send`, job);
export const updatePool = user => patch(`https://getjob.stechomeyazilim.info:5101/updatePoolBerlin/${user.ID}`, user);
export const deletePool = user => del(`https://getjob.stechomeyazilim.info:5101/deletePoolBerlin/${user}`);



export const getUsers = () => get(`https://getjob.stechomeyazilim.info:5101/getUsersBerlin/select/`);
export const addUser = job => post(`https://getjob.stechomeyazilim.info:5101/addUsersBerlin/send`, job);
export const updateUser = user => patch(`https://getjob.stechomeyazilim.info:5101/updateUsersBerlin/${user.ID}`, user);
export const deleteUser = user => del(`https://getjob.stechomeyazilim.info:5101/deleteUsersBerlin/${user}`);


export const getSong= () => get(`https://getjob.stechomeyazilim.info:5101/getSongBerlin/select/`);
export const addSong = job => post(`https://getjob.stechomeyazilim.info:5101/addSongBerlin/send`, job);
export const updateSong = user => patch(`https://getjob.stechomeyazilim.info:5101/updateSongBerlin/${user.ID}`, user);
export const deleteSong = user => del(`https://getjob.stechomeyazilim.info:5101/deleteSongBerlin/${user}`);

export const getSliders = () => get(`https://getjob.stechomeyazilim.info:5101/getSlidersBerlin/select/`);
export const updateSlider = user => patch(`https://getjob.stechomeyazilim.info:5101/updateSlidersBerlin/${user.ID}`, user);
export const deleteSlider = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteSlidersBerlin/${project}`);
  export const addSlider = job => post(`https://getjob.stechomeyazilim.info:5101/addSlidersBerlin/send`, job);




  export const getAdvertesing_Category = () => get(`https://getjob.stechomeyazilim.info:5101/getAdvertesing_CategoryBerlin/select/`);
  export const updateAdvertesing_Category = user => patch(`https://getjob.stechomeyazilim.info:5101/updateAdvertesing_CategoryBerlin/${user.ID}`, user);
  export const deleteAdvertesing_Category = project =>
    del(`https://getjob.stechomeyazilim.info:5101/deleteAdvertesing_CategoryBerlin/${project}`);
    export const addAdvertesing_Category = job => post(`https://getjob.stechomeyazilim.info:5101/addAdvertesing_CategoryBerlin/send`, job);
  
  


  export const getAdvertesing_Form = () => get(`https://getjob.stechomeyazilim.info:5101/getAdvertesing_FormBerlin/select/`);
  export const updateAdvertesing_Form = user => patch(`https://getjob.stechomeyazilim.info:5101/updateAdvertesing_FormBerlin/${user.ID}`, user);
  export const deleteAdvertesing_Form = project =>
    del(`https://getjob.stechomeyazilim.info:5101/deleteAdvertesing_FormBerlin/${project}`);
    export const addAdvertesing_Form = job => post(`https://getjob.stechomeyazilim.info:5101/addAdvertesing_FormBerlin/send`, job);
  
  

    export const getJob_Advertisement_Category = () => get(`https://getjob.stechomeyazilim.info:5101/getJob_Advertisement_CategoryBerlin/select/`);
    export const updateJob_Advertisement_Category = user => patch(`https://getjob.stechomeyazilim.info:5101/updateJob_Advertisement_CategoryBerlin/${user.ID}`, user);
    export const deleteJob_Advertisement_Category = project =>
      del(`https://getjob.stechomeyazilim.info:5101/deleteJob_Advertisement_CategoryBerlin/${project}`);
      export const addJob_Advertisement_Category = job => post(`https://getjob.stechomeyazilim.info:5101/addJob_Advertisement_CategoryBerlin/send`, job);
    
    



      export const getJob_Advertisement_SubCategory = () => get(`https://getjob.stechomeyazilim.info:5101/getJob_Advertisement_SubCategoryBerlin/select/`);
      export const updateJob_Advertisement_SubCategory = user => patch(`https://getjob.stechomeyazilim.info:5101/updateJob_Advertisement_SubCategoryBerlin/${user.ID}`, user);
      export const deleteJob_Advertisement_SubCategory = project =>
        del(`https://getjob.stechomeyazilim.info:5101/deleteJob_Advertisement_SubCategoryBerlin/${project}`);
        export const addJob_Advertisement_SubCategory = job => post(`https://getjob.stechomeyazilim.info:5101/addJob_Advertisement_SubCategoryBerlin/send`, job);
      
      

  
      export const getJob_Advertisement = () => get(`https://getjob.stechomeyazilim.info:5101/getJob_AdvertisementBerlin/select/`);
      export const deleteJob_Advertisement = project =>
        del(`https://getjob.stechomeyazilim.info:5101/deleteJob_AdvertisementBerlin/${project}`);
     
  

        export const getForum = () => get(`https://getjob.stechomeyazilim.info:5101/getForumBerlin/select/`);
        export const updateForum = user => patch(`https://getjob.stechomeyazilim.info:5101/updateForumBerlin/${user.ID}`, user);
        export const deleteForum = project =>
          del(`https://getjob.stechomeyazilim.info:5101/deleteForumBerlin/${project}`);
    
  export const getSubEducation = () => get(`https://getjob.stechomeyazilim.info:5101/getSlidersBerlin/select/`);
  export const updateSubEducation = user => patch(`https://getjob.stechomeyazilim.info:5101/updateSlidersBerlin/${user.ID}`, user);
  export const deleteSubEducation = project =>
    del(`https://getjob.stechomeyazilim.info:5101/deleteSlidersBerlin/${project}`);
    export const addSubEducation= job => post(`https://getjob.stechomeyazilim.info:5101/addSlidersBerlin/send`, job);
  

  export const getCompany_Category = () => get(`https://getjob.stechomeyazilim.info:5101/getCompany_CategoryBerlin/select/`);
export const updateCompany_Category = user => patch(`https://getjob.stechomeyazilim.info:5101/updateCompany_CategoryBerlin/${user.ID}`, user);
export const deleteCompany_Category = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteCompany_CategoryBerlin/${project}`);
  export const addCompany_Category = job => post(`https://getjob.stechomeyazilim.info:5101/addCompany_CategoryBerlin/send`, job);


  export const getCompany_SubCategory = () => get(`https://getjob.stechomeyazilim.info:5101/getCompany_SubCategoryBerlin/select/`);
export const updateCompany_SubCategory = user => patch(`https://getjob.stechomeyazilim.info:5101/updateCompany_SubCategoryBerlin/${user.ID}`, user);
export const deleteCompany_SubCategory = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteCompany_SubCategoryBerlin/${project}`);
  export const addCompany_SubCategory = job => post(`https://getjob.stechomeyazilim.info:5101/addCompany_SubCategoryBerlin/send`, job);



  export const getMainPage_Category= () => get(`https://getjob.stechomeyazilim.info:5101/getMainPage_CategoryBerlin/select/`);
export const updateMainPage_Category = user => patch(`https://getjob.stechomeyazilim.info:5101/updateMainPage_CategoryBerlin/${user.ID}`, user);
export const deleteMainPage_Category= project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteMainPage_CategoryBerlin/${project}`);
  export const addMainPage_Category = job => post(`https://getjob.stechomeyazilim.info:5101/addMainPage_CategoryBerlin/send`, job);





  export const getCompany_Advert_Price = () => get(`https://getjob.stechomeyazilim.info:5101/getCompany_Advert_PriceBerlin/select/`);
  export const updateCompany_Advert_Price= user => patch(`https://getjob.stechomeyazilim.info:5101/updateCompany_Advert_PriceBerlin/${user.ID}`, user);
  export const deleteCompany_Advert_Price = project =>
    del(`https://getjob.stechomeyazilim.info:5101/deleteCompany_Advert_PriceBerlin/${project}`);
    export const addCompany_Advert_Price = job => post(`https://getjob.stechomeyazilim.info:5101/addCompany_Advert_PriceBerlin/send`, job);
  

export const getCompany = () => get(`https://getjob.stechomeyazilim.info:5101/getCompanyBerlin/select/`);
export const addCompany  = user => post(`https://getjob.stechomeyazilim.info:5101/addCompanyBerlin/send`, user);
export const updateCompany  = user => patch(`https://getjob.stechomeyazilim.info:5101/updateCompanyBerlin/${user.ID}`, user);
export const deleteCompany  = user => del(`https://getjob.stechomeyazilim.info:5101/deleteCompanyBerlin/${user}`);


export const addEducation = user => post(`https://getjob.stechomeyazilim.info:5101/addEducationNefes/send`, user);
export const addProductCategory = user => post(`https://getjob.stechomeyazilim.info:5101/addProductsCategoryLuxun/send`, user);
export const deleteProductCategory = user => del(`https://getjob.stechomeyazilim.info:5101/deleteProductCategoryLuxun/${user}`);
export const updateProductCategory = user => patch(`https://getjob.stechomeyazilim.info:5101/updateProductCategoryLuxun/${user.ID}`, user);

export const updateEducation = user => patch(`https://getjob.stechomeyazilim.info:5101/updateEducationNefes/${user.ID}`, user);

export const updateAbout = user => patch(`https://getjob.stechomeyazilim.info:5101/updateAboutCastor/${user.ID}`, user);
export const updateContact = user => patch(`https://getjob.stechomeyazilim.info:5101/updateContactCastor/${user.ID}`, user);
export const getCategory = () => get(`https://getjob.stechomeyazilim.info:5101/getProductsCategoryLuxun/select/`);
export const getProducts1 = () => get(`https://getjob.stechomeyazilim.info:5101/getProductsLuxun/select/`);

export const getSSS = () => get(`https://getjob.stechomeyazilim.info:5101/getSSSPort/select/`);
export const getBlogs = () => get(`https://getjob.stechomeyazilim.info:5101/getBlogCastor/select/`);

export const addNewUser = user => post(`https://getjob.stechomeyazilim.info:5101/postForumBerlin/send`, user);

export const getProjectsNew = () => get(`https://getjob.stechomeyazilim.info:5101/getEducationNefes/select/`);
export const getEducation = () => get(`https://getjob.stechomeyazilim.info:5101/getEducationNefes/select/`);
export const getEducationSubItem = () => get(`https://getjob.stechomeyazilim.info:5101/getEducationSubItemNefes/select/`);
export const getPopup = () => get(`https://getjob.stechomeyazilim.info:5101/getPopupMega/select/`);
export const getWorkShop = () => get(`https://getjob.stechomeyazilim.info:5101/getSUBDOMAINMega/select/`);

export const getCourseSchedulesMega = () => get(`https://getjob.stechomeyazilim.info:5101/getCourseSchedulesMega/select/`);

export const getWorkshopSessionMega = () => get(`https://getjob.stechomeyazilim.info:5101/getWorkshopSessionMega/select/`);

export const getTeacherMega = () => get(`https://getjob.stechomeyazilim.info:5101/getTeacherMega/select/`);

export const getEventMega = () => get(`https://getjob.stechomeyazilim.info:5101/getEventMega/select/`);

export const getDynamicFormMega = () => get(`https://getjob.stechomeyazilim.info:5101/getPoolBerlin/select/`);
export const updateDynamicForm = user => patch(`https://getjob.stechomeyazilim.info:5101/updatePoolBerlin/${user.ID}`, user);
export const addDynamicForm = job => post(`https://getjob.stechomeyazilim.info:5101/postBerlin/send`, job);
export const deleteDynamicForm = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deletePoolBerlin/${project}`);


export const getMegaMega = () => get(`https://getjob.stechomeyazilim.info:5101/getMegaMega/select/`);

export const getMailsLists = () => get(`https://getjob.stechomeyazilim.info:5101/getContactMessageMega/select/`);

// add user

const postFakeLogin = data => get(`https://getjob.stechomeyazilim.info:5101/getUserLoginBerlin/select/${data.email}/${data.password}`,);

export const getEvents = () => get(`https://getjob.stechomeyazilim.info:5101/getEventMega/select/`);
export const getAbout = () => get(`https://getjob.stechomeyazilim.info:5101/getAboutCastor/select/`);
export const getCategories = () => get(`https://getjob.stechomeyazilim.info:5101/getEventCategoryMega/select/`);




export const updatePopup = user => patch(`https://getjob.stechomeyazilim.info:5101/patchPopupMega/${user.ID}`, user);

export const updateSubDomain = user => patch(`https://getjob.stechomeyazilim.info:5101/patchSUBDOMAINMega/${user.ID}`, user);

export const updateCourseSchedules = user => patch(`https://getjob.stechomeyazilim.info:5101/patchCourseSchedulesMega/${user.ID}`, user);

export const updateWorkshopSession = user => patch(`https://getjob.stechomeyazilim.info:5101/patchWorkShopSessionMega/${user.ID}`, user);

export const updateTeacher = user => patch(`https://getjob.stechomeyazilim.info:5101/patchUserMega/${user.ID}`, user);


export const updateSSS = user => patch(`https://getjob.stechomeyazilim.info:5101/updateSSSPort/${user.ID}`, user);

export const updateProduct = user => patch(`https://getjob.stechomeyazilim.info:5101/updateProductLuxun/${user.ID}`, user);
export const updateBlog = user => patch(`https://getjob.stechomeyazilim.info:5101/updateBlogCastor/${user.ID}`, user);



export const updateProject = user => patch(`https://getjob.stechomeyazilim.info:5101/patchUpdateProject/${user.ID}`, user);


export const addPopup = job => post(`https://getjob.stechomeyazilim.info:5101/addPopup/send`, job);


export const addSSS= job => post(`https://getjob.stechomeyazilim.info:5101/addSSSPort/send`, job);
export const addProduct = job => post(`https://getjob.stechomeyazilim.info:5101/addProductLuxun/send`, job);

export const addBlog = job => post(`https://getjob.stechomeyazilim.info:5101/addBlogCastor/send`, job);
export const addProject = job => post(`https://getjob.stechomeyazilim.info:5101/addProject/send`, job);



export const addMega = job => post(`https://getjob.stechomeyazilim.info:5101/addMega/send`, job);

export const addCourseMega = job => post(`https://getjob.stechomeyazilim.info:5101/addCourseMega/send`, job);

export const updateSubdomain = user => patch(`https://getjob.stechomeyazilim.info:5101/patchSUBDOMAINMega/${user.ID}`, user);



export const updateAuth = user => patch(`https://getjob.stechomeyazilim.info:5101/patchAuthMega/${user.ID}`, user);

export const updateMega = user => patch(`https://getjob.stechomeyazilim.info:5101/patchMegaMega/${user.ID}`, user);

export const updateGallery = user => patch(`https://getjob.stechomeyazilim.info:5101/patchGalleryMega/${user.ID}`, user);

export const updateEvent = event => patch(`https://getjob.stechomeyazilim.info:5101/patchEventMega/${event.ID}`, event);

export const addAppSetting = job => post(`https://getjob.stechomeyazilim.info:5101/postWSMega/send`, job);

export const addWorkshop2 = job => post(`https://getjob.stechomeyazilim.info:5101/postWorkshopMega/send`, job);



// delete user

export const getContact = () => get(`https://getjob.stechomeyazilim.info:5101/getContactCastor/select/`);
export const getAuthority = () => get(`https://getjob.stechomeyazilim.info:5101/getUsersTypeMega/select/`);
export const getWorkshop2 = () => get(`https://getjob.stechomeyazilim.info:5101/getWorkshopMega/select/`);

export const updateWorkShop2 = user => patch(`https://getjob.stechomeyazilim.info:5101/patchWorkshop2Mega/${user.ID}`, user);

export const getGallery = () => get(`https://getjob.stechomeyazilim.info:5101/getGalleryMega/select/`);

export const addNewEvent = event => post(`https://getjob.stechomeyazilim.info:5101/postEventMega/send/`, event);

export const getChats = () => get(`https://getjob.stechomeyazilim.info:5101/getContactMessageMega/select/`);

export const getMessages = (roomId = "") => get(`https://getjob.stechomeyazilim.info:5101/getContactMessageDetailMega/select/${roomId}`);

export const addNewSubdomain = event => post(`https://getjob.stechomeyazilim.info:5101/postSubdomainMega/send/`, event);
export const getReferances = () => get(`https://getjob.stechomeyazilim.info:5101/getReferancesMega/select/`);
// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);
// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);
// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });
// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });
/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

export const addReferances = event => post(`https://getjob.stechomeyazilim.info:5101/postReferancesMega/send/`, event);

export const updateReferances = user => patch(`https://getjob.stechomeyazilim.info:5101/patchReferancesMega/${user.ID}`, user);

export const getSubProject = (ID = "") => get(`https://getjob.stechomeyazilim.info:5101/getProjectSubItem/select/${ID}`);

export const getTeacherWorkshop = () => get(`https://getjob.stechomeyazilim.info:5101/getTeacherWorkshopMega/select/`);

export const addLessonsTime = event => post(`https://getjob.stechomeyazilim.info:5101/postLessonsTimeMega/send/`, event);

export const updateLessonsTime = user => patch(`https://getjob.stechomeyazilim.info:5101/patchTeacherWorkshopMega/${user.ID}`, user);

export const getClassAttendance = () => get(`https://getjob.stechomeyazilim.info:5101/getClassAttendanceMega/select/`);

export const getClassesMega = () => get(`https://getjob.stechomeyazilim.info:5101/getClassesMega/select/`);

export const getDynamicFormExtraMega = (ID = "") => get(`https://getjob.stechomeyazilim.info:5101/getDynamicFormExtraMega/select/${ID}`);

export const getDynamicFormExtraExtraMega = (ID = "") => get(`https://getjob.stechomeyazilim.info:5101/getDynamicFormExtraExtraMega/select/${ID}`);


export const addGallerry = job => post(`https://getjob.stechomeyazilim.info:5101/postGalleryMega/send`, job);

export const addMultipleGalleryForm = job => post(`https://getjob.stechomeyazilim.info:5101/postGalleryMultipleMega/send`, job);


export const deleteDynamicFormExtraExtra = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteDynamicExtraExtraFormMega/${project}`);


export const deleteDynamicFormExtra = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteDynamicFormExtraMega/${project}`);

export const deleteCourseSchedules = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteCourseSchedulesMega/${project}`);

export const deleteGallery = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteGalleryMega/${project}`);

export const deleteReferances = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteReferancesMega/${project}`);

export const deletePopup = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deletePopupMega/${project}`);



  export const deleteSSS = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteSSSPort/${project}`);
  





export const deleteProduct = project =>
del(`https://getjob.stechomeyazilim.info:5101/deleteProductLuxun/${project}`);

  export const deleteBlog = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteblogCastor/${project}`);


  
export const deleteMega = project =>
  del(`https://getjob.stechomeyazilim.info:5101/deleteMega/${project}`);

export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get maillist
//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER);

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};
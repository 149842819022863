import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";


//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{props.messagesNew.length}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  Hepsini Görüntüle
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
         { props.messagesNew.map((item, i) => {
                        return (
                          <Link key={i} to="" className="text-reset notification-item">
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title bg-primary rounded-circle font-size-16">
                                <i className="bx bx-cart" />
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mt-0 mb-1">
                              {item.Subject}
                              </h6>
                              <div className="font-size-12 text-muted">
                                <p className="mb-1">
                                  {item.Contact_Message_Messages}
                                </p>
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline" />{" "}
                                  {moment(item.CreatedDateTime).format('DD/MM/YYYY')}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                        )
                        })}
          
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
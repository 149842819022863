import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import moment from "moment";

import {
  getArticleDay as onGetArticleDay,
  addArticleDay as onAddArticleDay,
  updateArticleDay as onUpdateArticleDay,
  deleteArticleDay as onDeleteArticleDay,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "MAKALE | BERLİN ESNAF ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState('');


  const [getHtmlDataLong, setHtmlDataLong] = useState();

  const [getFileSubMedia, setFileSubMedia] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const [getIsActive, setIsActive] = useState(false);
  const [getIsService, setIsService] = useState(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.ArticleDay_Title) || "",

      link: (contactEdit && contactEdit.ArticleDay_Desc) || "",

     
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          ArticleDay_Title: values.title,
          ArticleDay_LongDesc: Base64.encode(getHtmlDataLong),
          Users_ID: getCategoryID,
          ArticleDay_Image: getFileName,
          ArticleDay_Desc: values.link,


        };
        dispatch(onUpdateArticleDay(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          ArticleDay_Title: values["title"],
          ArticleDay_LongDesc: Base64.encode(getHtmlDataLong),
          Users_ID: getCategoryID,      
          ArticleDay_Desc: values["link"],
          ArticleDay_Image: getFileName,
        



        };
        // save new user
        dispatch(onAddArticleDay(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.articleday,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.ArticleDay_Image ? (
              <div className="avatar-xs">
                {cellProps.ArticleDay_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.ArticleDay_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.ArticleDay_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Başlık",
        accessor: "ArticleDay_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
     

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersBerlin/select/`)
      .then((res) => {
        setCategory(res.data)
      })


  }

 




  useEffect(() => {
    _getAuthData()
    


    if (users && !users.length) {
      dispatch(onGetArticleDay());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };




  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

  


    setCategoryID(user.Users_ID)


    if (user.EditForm == 0) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }



    if (user.EditForm == 0) {
      setIsService(false)
    } else {
      setIsService(true)
    }

    setIsEdit(true);


    toggle();
  };




  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteArticleDay(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const _setChoiceCategorySecond = arg => {
    setCategoryIDSecond(arg.target.value)
  }
  const _setChoiceCategoryThird = arg => {
    setCategoryIDThird(arg.target.value)
  }

  const _setChoiceCategoryForth = arg => {
    setCategoryIDForth(arg.target.value)
  }
  const _setChoiceCategoryFifth = arg => {
    setCategoryIDFifth(arg.target.value)
  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file; // loader.file bir Promise nesnesi döndürür
  
            // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
            const body = new FormData();
            body.append('dataFile', file);
            console.log("file1c2313",file);
  
            try {
              const response = await axios.request({
                method: "POST",
                url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
                data: body, // axios'ta "data" kullanın, "body" değil
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
  
              resolve({
                default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
              });
            } catch (error) {
              // Hata oluştuğunda throw ile hata fırlatın
              throw error;
            }
  
            // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
          } catch (error) {
            reject(error);
          }
        });
      },
      abort: () => {}
    };
  }
  
  
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  
  const _addMedia = () => {


    getFileSubMediaAdd.map((item, index) => {
      const newUser = {
        Education_SubItem_ID: getSubMediaID,
        EducationSubItem_Media_Type: item.fileType,
        EducationSubItem_Media_Link: item.fileName
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addSubEducationMediaDiz/send', newUser)

      dispatch(onGetArticleDay());

      toggleMedia();
    })
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          console.log("lnslkdf", result.file)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Makale Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddArticleDay={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Medya Ekle"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>


                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatusBanner}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*,video/*"
                            />
                          </div>

                        </Col>
                      </Row>
                      <Row>

                        {getFileSubMedia.map((item, index) => (
                          item.EducationSubItem_Media_Type == "video/mp4" ?
                            <div key={index}>
                              <video width="150" height="150" controls>
                                <source src={'https://megasubdomain.stechomeyazilim.info/' + item.EducationSubItem_Media_Link} type="video/mp4" />
                              </video>

                            </div> :

                            <div key={index}>
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + item.EducationSubItem_Media_Link} alt="" className="avatar-sm" />
                            </div>
                        ))}



                        <Col>
                          <div className="text-end">
                            <button
                              onClick={() => _addMedia()}
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Medyaları Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>

                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Makale Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                  <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Name_Surname}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>



                     
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Makale Başlığını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>



                         




                            <div className="mb-3">
                              <Label className="form-label">Kısa Açıklama Giriniz</Label>
                              <Input
                                name="link"
                                label="link"
                                type="text"
                                placeholder="Kısa Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.link || ""}
                                invalid={
                                  validation.touched.link &&
                                    validation.errors.link
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.link &&
                                validation.errors.link ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.link}
                                </FormFeedback>
                              ) : null}
                            </div>



                        







                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>




                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={Editor}
                                  data={getHtmlDataLong}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLong(data)

                                  }}
                                />
                              </Form>
                            </div>






                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import axios from "axios";

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | CASTOR Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);
  const [getHtmlDataLongEng, setHtmlDataLongEng] = useState(null);
  const [getHtmlDataLongThird, setHtmlDataLongThird] = useState(null);
  const [getFileName, setFileName] = useState('');


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    if (user.AboutDesc != null) {
      setHtmlDataLong(Base64.decode(user.AboutDesc))

    }

    if (user.AboutDescEng != null) {
      setHtmlDataLong(Base64.decode(user.AboutDescEng))

    }


    if (user.AboutDescThird != null) {
      setHtmlDataLong(Base64.decode(user.AboutDescThird))

    }

    setIsEdit(true);


    toggle();
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file; // loader.file bir Promise nesnesi döndürür
  
            // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
            const body = new FormData();
            body.append('dataFile', file);
            console.log("file1c2313",file);
  
            try {
              const response = await axios.request({
                method: "POST",
                url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
                data: body, // axios'ta "data" kullanın, "body" değil
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
  
              resolve({
                default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
              });
            } catch (error) {
              // Hata oluştuğunda throw ile hata fırlatın
              throw error;
            }
  
            // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
          } catch (error) {
            reject(error);
          }
        });
      },
      abort: () => {}
    };
  }
  
  
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mission: (getContactEdit && getContactEdit.Mission) || '',
      vission: (getContactEdit && getContactEdit.Vision) || '',
      shortdesc: (getContactEdit && getContactEdit.ShortDesc) || '',


      missioneng: (getContactEdit && getContactEdit.MissionEng) || '',
      vissioneng: (getContactEdit && getContactEdit.VisionEng) || '',
      shortdesceng: (getContactEdit && getContactEdit.ShortDescEng) || '',



      missionthird: (getContactEdit && getContactEdit.MissionThird) || '',
      vissionthird: (getContactEdit && getContactEdit.VisionThird) || '',
      shortdescthird: (getContactEdit && getContactEdit.ShotDescThird) || '',
    },
    validationSchema: Yup.object({
      shortdesc: Yup.string().required("Lütfen Kısa Açıklama Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,

        About_Desc: Base64.encode(getHtmlDataLong),
        AboutDescEng: Base64.encode(getHtmlDataLongEng),
        AboutDescThird: Base64.encode(getHtmlDataLongThird),

        Mission: values.mission,
        Vision: values.vission,
        ShortDesc: values.shortdesc,


        MissionEng: values.missioneng,
        VisionEng: values.vissioneng,
        ShortDescEng: values.shortdesceng,


        MissionThird: values.missionthird,
        VisionThird: values.vissionthird,
        ShotDescThird: values.shortdescthird,

      };
      // update order
      dispatch(onUpdateAbout(updateOrder));
      validation.resetForm();

    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    console.log("nsdlf", users)
    if (users.length > 0) {
      setContactEdit(users[0]);
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Hakkımızda Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="productname">Neden Biz kısa açıklama</Label>
                        <Input
                          id="shortdesc"
                          name="shortdesc"
                          type="text"
                          className="form-control"
                          placeholder="Başlık"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.shortdesc || ""}
                          invalid={
                            validation.touched.shortdesc && validation.errors.shortdesc ? true : false
                          }
                        />
                        {validation.touched.shortdesc && validation.errors.shortdesc ? (
                          <FormFeedback type="invalid">{validation.errors.shortdesc}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="productname">Neden Biz kısa açıklama Eng</Label>
                        <Input
                          id="shortdesceng"
                          name="shortdesceng"
                          type="text"
                          className="form-control"
                          placeholder="Başlık"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.shortdesceng || ""}
                          invalid={
                            validation.touched.shortdesceng && validation.errors.shortdesceng ? true : false
                          }
                        />
                        {validation.touched.shortdesceng && validation.errors.shortdesceng ? (
                          <FormFeedback type="invalid">{validation.errors.shortdesceng}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="productname">Neden Biz kısa açıklama Third</Label>
                        <Input
                          id="shortdescthird"
                          name="shortdescthird"
                          type="text"
                          className="form-control"
                          placeholder="Başlık"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.shortdescthird || ""}
                          invalid={
                            validation.touched.shortdescthird && validation.errors.shortdescthird ? true : false
                          }
                        />
                        {validation.touched.shortdescthird && validation.errors.shortdescthird ? (
                          <FormFeedback type="invalid">{validation.errors.shortdescthird}</FormFeedback>
                        ) : null}

                      </div>







                      <div className="mb-3">
                        <Label htmlFor="productname">Misyon</Label>
                        <Input
                          id="mission"
                          name="mission"
                          type="text"
                          className="form-control"
                          placeholder="Misyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mission || ""}
                          invalid={
                            validation.touched.mission && validation.errors.mission ? true : false
                          }
                        />
                        {validation.touched.mission && validation.errors.mission ? (
                          <FormFeedback type="invalid">{validation.errors.mission}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="productname">Misyon Eng</Label>
                        <Input
                          id="missioneng"
                          name="missioneng"
                          type="text"
                          className="form-control"
                          placeholder="Misyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.missioneng || ""}
                          invalid={
                            validation.touched.missioneng && validation.errors.missioneng ? true : false
                          }
                        />
                        {validation.touched.missioneng && validation.errors.missioneng ? (
                          <FormFeedback type="invalid">{validation.errors.missioneng}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="productname">Misyon Third</Label>
                        <Input
                          id="missionthird"
                          name="missionthird"
                          type="text"
                          className="form-control"
                          placeholder="Misyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.missionthird || ""}
                          invalid={
                            validation.touched.missionthird && validation.errors.missionthird ? true : false
                          }
                        />
                        {validation.touched.missionthird && validation.errors.missionthird ? (
                          <FormFeedback type="invalid">{validation.errors.missionthird}</FormFeedback>
                        ) : null}

                      </div>





                       <div className="mb-3">
                        <Label htmlFor="productname">Vizyon</Label>
                        <Input
                          id="vission"
                          name="vission"
                          type="text"
                          className="form-control"
                          placeholder="Vizyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.vission || ""}
                          invalid={
                            validation.touched.vission && validation.errors.vission ? true : false
                          }
                        />
                        {validation.touched.vission && validation.errors.vission ? (
                          <FormFeedback type="invalid">{validation.errors.vission}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="productname">Vizyon Eng</Label>
                        <Input
                          id="vissioneng"
                          name="vissioneng"
                          type="text"
                          className="form-control"
                          placeholder="Vizyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.vissioneng || ""}
                          invalid={
                            validation.touched.vissioneng && validation.errors.vissioneng ? true : false
                          }
                        />
                        {validation.touched.vissioneng && validation.errors.vissioneng ? (
                          <FormFeedback type="invalid">{validation.errors.vissioneng}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Vizyon Third</Label>
                        <Input
                          id="vissionthird"
                          name="vissionthird"
                          type="text"
                          className="form-control"
                          placeholder="Vizyon"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.vissionthird || ""}
                          invalid={
                            validation.touched.vissionthird && validation.errors.vissionthird ? true : false
                          }
                        />
                        {validation.touched.vissionthird && validation.errors.vissionthird ? (
                          <FormFeedback type="invalid">{validation.errors.vissionthird}</FormFeedback>
                        ) : null}

                      </div>






                      <div className="mb-3">
                        <Label className="form-label">Uzun Açıklama</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataLong}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin]
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLong(data)

                            }}
                          />
                        </Form>
                      </div>



                      <div className="mb-3">
                        <Label className="form-label">Uzun Açıklama Eng</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataLongEng}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLongEng(data)

                            }}
                          />
                        </Form>
                      </div>



                      <div className="mb-3">
                        <Label className="form-label">Uzun Açıklama Third</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataLongThird}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLongThird(data)

                            }}
                          />
                        </Form>
                      </div>


                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct

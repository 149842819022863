import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,


  Container, Row, Col,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status } from "./contactlistCol";

import { Box, Dialog, DialogTitle, DialogContent, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import GlobalStyles from "@mui/material/GlobalStyles";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import "jspdf-autotable";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import moment from "moment";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  getMainPage_Category as onGetMainPage_Category,
  addMainPage_Category as onAddMainPage_Category,
  updateMainPage_Category as onUpdateMainPage_Category,
  deleteMainPage_Category as onDeleteMainPage_Category,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      ".ck.ck-balloon-panel": {
        zIndex: "1400 !important", // Put a higher value that your MUI Dialog (generaly 1300)
      },
    }}
  />
);


function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file; // loader.file bir Promise nesnesi döndürür

          // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
          const body = new FormData();
          body.append('dataFile', file);
          console.log("file1c2313",file);

          try {
            const response = await axios.request({
              method: "POST",
              url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
              data: body, // axios'ta "data" kullanın, "body" değil
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });

            resolve({
              default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
            });
          } catch (error) {
            // Hata oluştuğunda throw ile hata fırlatın
            throw error;
          }

          // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {}
  };
}


function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}



const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Anasayfa Kategorileri | BERLİN ESNAF ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState('');
  const [getIsActive, setIsActive] = useState(false);
  
  const [getHtmlDataLong, setHtmlDataLong] = useState();
   
  const [getHtmlDataLongSecond, setHtmlDataLongSecond] = useState();

  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.MainPage_Category_Title) || "",

      sort: (contactEdit && contactEdit.MainPage_Category_Sort) || "",
 

    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {
        var localDate = moment().local();

        const updateUser = {
          ID: contactEdit.ID,
          MainPage_Category_Title: values.title,
          MainPage_Category_Sort:values.sort,
          MainPage_Category_Main_Desc: Base64.encode(getHtmlDataLong),
          
          MainPage_Category_InnerMain_Desc:Base64.encode(getHtmlDataLongSecond),
          IsActive: getIsActive,
         
          MainPage_Category_Image:getFileName,
          Created_DateTime:localDate

        };
        dispatch(onUpdateMainPage_Category(updateUser));
        showToast(true,'update')
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          MainPage_Category_Title: values["title"],
          MainPage_Category_Sort: values["sort"],
          MainPage_Category_Main_Desc: Base64.encode(getHtmlDataLong),
          MainPage_Category_InnerMain_Desc:Base64.encode(getHtmlDataLongSecond),

          IsActive: getIsActive,
          MainPage_Category_Image:getFileName,
          Created_DateTime:localDate
        };
        // save new user
        dispatch(onAddMainPage_Category(newUser));
        showToast(true,'add')
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.mainpagecategory,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.MainPage_Category_Image ? (
              <div className="avatar-xs">
                {cellProps.MainPage_Category_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.MainPage_Category_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.MainPage_Category_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Başlık",
        accessor: "MainPage_Category_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Sıralama",
        accessor: "MainPage_Category_Sort",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Aktif Mi?",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

        
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );



  useEffect(() => {

    if (users && !users.length) {
      dispatch(onGetMainPage_Category());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };


  const handleUserClick = (arg) => {
    const user = arg;


 
    setEditContact(user);

    setIsEdit(true);

    setIsActive(user.IsActive)

    if(user.MainPage_Category_Main_Desc != null){
      setHtmlDataLong(Base64.decode(user.MainPage_Category_Main_Desc))
    }

    if(user.MainPage_Category_InnerMain_Desc != null){
      setHtmlDataLongSecond(Base64.decode(user.MainPage_Category_InnerMain_Desc))
    }
    
    setFileName(user.MainPage_Category_Image)

    toggle();
  };


  

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteMainPage_Category(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };



  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    console.log("file12313",file)
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)
        }
      }
    }
  }


  function showToast(type,type2) {
    let ele = null
    let message = "";
  
     if(type == true){
      if(type2 == 'update'){
       ele = "success"
       message = "Başarıyla Güncellendi"
      }else{
          ele = "success"
       message = "Başarıyla Kaydedildi"
      }
     
    } else{
       ele = "error"
       message = getFalseDesc
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Anasayfa kategorileri Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddMainCat={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

<Dialog  open={modal} onClose={toggle} disableEnforceFocus maxWidth="md" fullWidth>
<DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6">Düzenle</Typography>
        <IconButton
          aria-label="close"
          onClick={toggle}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
<Box
      sx={{
        ".ck-editor__editable_inline": {
          height: "200px",
          border: (theme) =>
            theme.palette.mode === "light"
              ? "1px solid rgba(0, 0, 0, 0.20) !important"
              : "1px solid rgba(200, 200, 200, 0.25) !important",
          borderTop: "1px !important",
        },
      }}
    >
      {inputGlobalStyles}  
           
                 
                   
                      <Form
                        onSubmit={e => {
                          console.log("lşmsdlfş")
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Kategori Başlığını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>




                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="text"
                                placeholder="Sıralama Başlığını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>

                            <div >
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + getFileName} alt="" className="avatar-xl" />
                            </div>

                   

                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama</Label>

                              <Form method="post">
                              <CKEditor
                                  editor={Editor}
                                  data={getHtmlDataLong}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin],
                                   
                                  }}
                                  onReady={editor => {
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLong(data)

                                  }}
                                />
                              </Form>
                            </div>


                      
                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama 2</Label>

                              <Form method="post">
                              <CKEditor
                                  editor={Editor}
                                  data={getHtmlDataLongSecond}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin]
                                  }}
                                  onReady={editor => {
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLongSecond(data)

                                  }}
                                />
                              </Form>
                            </div>


                         

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                
                      </Box>
                      </DialogContent>
                      </Dialog>
                 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
